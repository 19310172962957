import React, { useEffect, useRef, useState } from "react"
import Flickity from "react-flickity-component"
import "flickity/dist/flickity.min.css"
import { Link, graphql } from "gatsby"

//component
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LiveChat from "../../components/Home/livechat";
import TestimonialsText from "../../components/Home/testimonials-text"

// img
import IronBannerimg from "../../../images/uploads/ironstudio/iron-main-banner.png"
import IronLaptopimg from "../../../images/uploads/ironstudio/iron-laptop-img.png"
import IronAboutimg from "../../../images/uploads/ironstudio/iron-about-img.png"
import IronAboutbg from "../../../images/uploads/ironstudio/iron-about-bg.png"
import IronChallngebg from "../../../images/uploads/ironstudio/iron-challnges-bg.png"
import IronSol1 from "../../../images/uploads/ironstudio/iron-sol-1.png"
import IronSol2 from "../../../images/uploads/ironstudio/iron-sol-2.png"
import IronSol3 from "../../../images/uploads/ironstudio/iron-sol-3.png"
import ironSolbg from "../../../images/uploads/ironstudio/iron-sol-bg.png"
import Ironstoress1 from "../../../images/uploads/ironstudio/decodeup-iron-studio-slider-ss-1.png"
import Ironstoress2 from "../../../images/uploads/ironstudio/decodeup-iron-studio-slider-ss-2.png"
import Ironstoress3 from "../../../images/uploads/ironstudio/decodeup-iron-studio-slider-ss-3.png"
import Ironstoress4 from "../../../images/uploads/ironstudio/decodeup-iron-studio-slider-ss-4.png"
import Ironstoress5 from "../../../images/uploads/ironstudio/decodeup-iron-studio-slider-ss-5.png"
import Ironstoress6 from "../../../images/uploads/ironstudio/decodeup-iron-studio-slider-ss-6.png"
import Ironstoress7 from "../../../images/uploads/ironstudio/decodeup-iron-studio-slider-ss-7.png"
import Ironstoress8 from "../../../images/uploads/ironstudio/decodeup-iron-studio-slider-ss-8.png"


import addcs1 from "../../../images/uploads/decodeup-additional-case-study-square-one-media.png"
import addcs2 from "../../../images/uploads/decodeup-additional-case-study-medication-tracker.png"

const IronsTudio = props => {

  const SEOdata = props.data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.pageData.edges[0].node.frontmatter?.Hreflang

  const [margin, setMargin] = useState()
  const flRef = useRef()

  useEffect(() => {
    let box = document.querySelector(".container")
    let width = box.offsetWidth
    let innerw = window.innerWidth
    let paddingApplied = innerw - width

    setMargin(paddingApplied)
  })

  const options = {
    // contain: true,
    // wrapAround: true,
    // initialIndex: 2,
    // pageDots: false,
    // autoPlay: false,
    // prevNextButtons: false,
    // adaptiveHeight: true,
    lazyLoad: true,
    prevNextButtons: true,
    cellAlign: "left",
    contain: true,
    freeScroll: true,
    pageDots: false,
    percentPosition: true,
    adaptiveHeight: true,
  }

  const onMouseEnter = () => {
    flRef.current.flkty.player.play()
  }
  const onMouseLeave = () => {
    flRef.current.flkty.player.stop()
  }
  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "iron-studios"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />

        {/* banner  section */}
        <section className="h-full  xl:h-screen case-draft-section">
          <div className="h-full container mx-auto  ">
            <div className="pb-20 pt-1-63 md:py-2-00 lg:pt-64 h-full flex flex-col justify-center">
              <div className="mb-6-6 lg:mb-50 w-full  case-study-project-heading ">
                <h4 className="pr-5 lg:pl-0 mb-1-2 lg:mb-2-2 font-poppings-normal text-p3 lg:text-p2 text-term-primary">
                  Redesign Website & Improvement UI/UX
                </h4>
                <h1 className="mb-3 lg:mb-5 font-poppings-bold text-h2 lg:text-h0 xl:text-h1 text-term-primary">
                  Action Figures Online Studio
                </h1>
                <p className="w-full lg:w-11/20  font-worksans-normal text-p4  md:text-h5 lg:text-p2 text-term-primary">
                  In 2020, the COVID-19 pandemic impacted every single business
                  in the world in some way, and most of that impact was
                  negative. BUNDL was established to address the challenges of
                  the pandemic, which hurt retail, hospitality and tourism the
                  most. This platform helps companies to collaborate and offer
                  exclusive deals.
                </p>
              </div>

              <div className="project-details-draft">
                <div className="flex flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      CLIENT
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Bernado M.
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      COUNTRY
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Brazil
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      SERVICES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Development, SEO, Maintenance and Support, UI/UX Design, Product management, Hosting
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      TECHNOLOGIES USED
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Wordpress, WooCommerce, AWS, Braintree
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      INDUSTRIES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Business to Consumer & E-Commerce
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Iron Content Start */}

        <section className="iron-bg mb-60 md:mb-20 xl:mb-1-40">
          {/* Banner Image section */}
          <div className="w-full text-center relative iron-bg-banner ">
            <img
              className="w-full h-full object-cover"
              src={IronBannerimg}
              alt="Iron Studio DecodeUp Technologies"
            />
          </div>
          <div className="-mt-60 lg:-mt-3-50 w-full text-center relative">
            <div className="container mx-auto">
              <img
                className="w-full h-full object-cover"
                src={IronLaptopimg}
                alt="Iron Studio DecodeUp Technologies"
              />
            </div>
          </div>

          {/* About Section */}
          <div className="mb-60 md:mb-20 xl:mb-1-40 md:-mt-20 xl:-mt-1-40">
            <div className="container mx-auto">
              <div className="">
                <h2 className="mb-4 md:mb-6-6 lg:mb-14 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-white text-left">
                  About Project
                </h2>
              </div>
              <div className="flex flex-wrap sm:flex-nowrap gap-8 md:gap-8 lg:gap-16">
                <div className="w-full lg:w-1/2">
                  <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-white">
                    A Redefined Web Experience
                  </h3>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-white font-worksans-normal">
                    You’ve heard it before, but it's worth repeating: you need a
                    new website, regardless of your size or industry.
                  </p>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-white font-worksans-normal">
                    Retail, manufacturing, advocacy, charity, finance,
                    real-estate, education, entertainment, food & beverage,
                    finance, travel, the list goes on. Whether you’re an SMB or
                    enterprise-level corporation, your website is your digital
                    calling card in your industry:
                  </p>

                  <ul className="mb-5 flex flex-col gap-3 md:gap-5 list-none somp-solution-ul text-white font-worksans-normal text-p4 lg:text-p3 xl:text-p2">
                    <li className="ml-6 lg:ml-10 relative">
                      70-80% of consumers research a business online while
                      looking for new products, services, or solutions
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      84% of consumers will not complete a purchase on an
                      unsecured website
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      Conversion rates drop up to 20% for each second your site
                      takes to load
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      It takes just 50 milliseconds – 0.05 seconds – for a
                      visitor to form an opinion of your brand after viewing
                      your website
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      Over half of people (57%) will not recommend a business or
                      association with a poorly designed mobile website
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      More than one-third of visitors (38%) will stop engaging
                      and leave your site if it has unattractive content and
                      layout
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      About half (47%) of users expect a website to load in two
                      seconds or less
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      75% of people judge the credibility of a company based on
                      its website design
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      Statistics like these make two things very clear:
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      You need a website (yes, you), and
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      It must to be fast, attractive, and modern
                    </li>
                  </ul>
                </div>

                <div className="w-full lg:w-1/2 text-white">
                  <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold">
                    A website with features for all ages
                  </h3>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 font-worksans-normal">
                    Toy products were the main focus and children love toys so
                    the clients wanted to sell products with EMI-based payment
                    directly from the website so anyone can afford any item from
                    the website and pay monthly by EMI.
                  </p>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 font-worksans-normal">
                    A slow, clunky, antiquated-looking site arguably does more
                    harm than not having a website at all. If you have one, you
                    need to periodically re-evaluate it. Like a physical home or
                    office, your digital address should be renovated from time
                    to time. By knowing this, you’re ahead of the curve.
                  </p>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 font-worksans-normal">
                    Since 2012, Iron Studios has been producing extremely
                    detailed collectible figures, as the first Brazilian company
                    with licensed products 100% made for collectors.
                  </p>
                  <p className="text-p4 lg:text-p3 xl:text-p2 font-worksans-normal">
                    With a large following of fans worldwide, Iron Studios has
                    been building an audience that is increasingly demanding and
                    attentive to the tiniest detail, winning over some of the
                    most popular and strict collector forums and groups in the
                    world.
                  </p>
                  <div className="mt-4 ml-auto max-w-3-22 w-full">
                    <img src={IronAboutimg} alt="Iron Studio" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Section About bg image */}
          <div>
            <div className="container mx-auto">
              <img src={IronAboutbg} alt="Iron Studio" />
            </div>
          </div>

          {/* The challenges  */}
          <div className="mb-60 md:mb-20 xl:mb-1-40 ">
            <div className="container mx-auto">
              <div className="mb-10 mx-auto w-full max-w-6-13 text-center">
                <h2 className="mb-4 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-white">
                  The Challenges
                </h2>
                <p className="text-p4 lg:text-p3 xl:text-p2 font-worksans-normal text-center text-white">
                  There were lots of challenges on this project as client come
                  to us with a bad website and so many complex issues that were
                  difficult to track.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 font-worksans-normal text-center text-white">
                  Issues included the following below:
                </p>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-6 sm:gap-x-6">
                <div className="rounded-lg flex flex-col justify-between iron-challange-inner">
                  <p className="pt-6 px-6 text-p4 lg:text-p3 xl:text-p2 font-worksans-normal text-white">
                    Products images were not uploading from the system even though the memory limit was at the top-notch setting and other settings were also right but their version of WordPress was corrupted.
                  </p>
                  <h2 className="-mt-2 pl-2 pb-2 text-right font-poppings-bold">
                    01
                  </h2>
                </div>
                <div className="rounded-lg flex flex-col justify-between iron-challange-inner">
                  <p className="pt-6 px-6 text-p4 lg:text-p3 xl:text-p2 font-worksans-normal text-white">
                    The website was taking so a large amount of time to load
                    even with few images on the page. They were not able to send
                    invoices to customers from their internal system despite
                    having Woo Commerce was settled there.
                  </p>
                  <h2 className="-mt-2 pl-2 pb-2 text-right font-poppings-bold">
                    02
                  </h2>
                </div>
                <div className="rounded-lg flex flex-col justify-between iron-challange-inner">
                  <p className="pt-6 px-6 text-p4 lg:text-p3 xl:text-p2 font-worksans-normal text-white">
                    Nginx was down repeatedly due to corrupted code and other
                    issues. The client wanted to implement EMI-based payment
                    system on the website.
                  </p>
                  <h2 className="-mt-2 pl-2 pb-2 text-right font-poppings-bold">
                    03
                  </h2>
                </div>
                <div className="rounded-lg flex flex-col justify-between iron-challange-inner">
                  <p className="pt-6 px-6 text-p4 lg:text-p3 xl:text-p2 font-worksans-normal text-white">
                    The checkout page was showing the wrong pricing with
                    shipping charges although the settings were correct. The
                    design of each page was very poor and not exciting.
                  </p>
                  <h2 className="-mt-2 pl-2 pb-2 text-right font-poppings-bold">
                    04
                  </h2>
                </div>
                <div className="rounded-lg flex flex-col justify-between iron-challange-inner">
                  <p className="pt-6 px-6 text-p4 lg:text-p3 xl:text-p2 font-worksans-normal text-white">
                    Together, these issues contributed to a poor user experience
                    and a boring web design, less than stellar organization, and
                    information overload that had a negative effect on their
                    perceived expertise and brand.
                  </p>
                  <h2 className="-mt-2 pl-2 pb-2 text-right font-poppings-bold">
                    05
                  </h2>
                </div>
                <div className="rounded-lg flex flex-col justify-between iron-challange-inner">
                  <p className="pt-6 px-6 text-p4 lg:text-p3 xl:text-p2 font-worksans-normal text-white">
                    Something had to be done immediately. And that’s our
                    specialty.
                  </p>
                  <h2 className="-mt-2 pl-2 pb-2 text-left font-poppings-bold text-7xl">
                    Our Specialty
                  </h2>
                </div>
              </div>
            </div>
          </div>

          {/* Section Challenges bg image */}
          <div>
            <div className="container mx-auto">
              <img src={IronChallngebg} alt="Iron Studio" />
            </div>
          </div>

          {/* THe Solutions Sections */}

          <div className="mb-60 md:mb-20">
            <div className="container mx-auto">
              <div className="mb-10 mx-auto w-full max-w-6-13 text-center">
                <h2 className="mb-4 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-white">
                  The Solutions
                </h2>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-6 sm:gap-x-6">
                <div className="px-6 pt-6 pb-5 rounded-lg flex flex-col justify-between iron-sol-inner relative overflow-hidden">
                  <div>
                    <h3 className="mb-2 lg:mb-5 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-white">
                      DecodeUp Technologies came to the rescue
                    </h3>
                    <p className="mb-4 text-p4 lg:text-p3 xl:text-p2 font-worksans-normal text-white">
                      DecodeUp is not in the business of giving clients
                      cookie-cutter solutions.
                    </p>
                    <p className="mb-4 text-p4 lg:text-p3 xl:text-p2 font-worksans-normal text-white">
                      Our designs and creations are always custom-built for each
                      client and their customers. The initial discovery call or
                      is pivotal to our process.
                    </p>
                    <p className="text-p4 lg:text-p3 xl:text-p2 font-worksans-normal text-white">
                      After this, our design and development team got to work.
                    </p>
                  </div>
                  <div className="absolute inset-x-0 bottom-0">
                    <img src={IronSol1} alt="Iron Studio" />
                  </div>
                </div>
                <div className="px-6 pt-6 pb-5 rounded-lg flex flex-col justify-between iron-sol-inner relative overflow-hidden">
                  <div>
                    <h3 className="mb-2 lg:mb-5 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-white">
                      Well-structured UI/UX Design & Dynamic Features
                    </h3>
                    <p className="mb-4 text-p4 lg:text-p3 xl:text-p2 font-worksans-normal text-white">
                      We created a new design by studying the websites of Iron
                      Studios' competition and other popular sites in their
                      target market. We formed a refreshed UX/UI flow to appeal
                      to users and resonate with them. We conducted a
                      comprehensive SEO analysis and optimized the new site and
                      its keywords for the search success.
                    </p>
                    <p className="text-p4 lg:text-p3 xl:text-p2 font-worksans-normal text-white">
                      We recorded the entire WordPress framework and fixed all
                      of the challenging issues they were facing. Finally we
                      migrated & upgraded the server configurations according to
                      the site's requirement and traffic.
                    </p>
                  </div>
                  <div className="absolute inset-x-0 bottom-0">
                    <img src={IronSol2} alt="Iron Studio" />
                  </div>
                </div>
                <div className="px-6 pt-6 pb-5 rounded-lg flex flex-col justify-between iron-sol-inner relative overflow-hidden">
                  <div>
                    <h3 className="mb-2 lg:mb-5 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-white">
                      Customized payment and payment flow
                    </h3>
                    <p className="mb-4 text-p4 lg:text-p3 xl:text-p2 font-worksans-normal text-white">
                      We established an affiliate program by ShareASale &
                      Implemented a custom brain tree API for EMI-based
                      recurring payments for all sales.
                    </p>
                    <p className="mb-4 text-p4 lg:text-p3 xl:text-p2 font-worksans-normal text-white">
                      We created a trusted, safe payment option for consumers.
                    </p>
                    <p className="text-p4 lg:text-p3 xl:text-p2 font-worksans-normal text-white">
                      They had a detailed list of features they wanted. DecodeUp
                      met all of those desires.
                    </p>
                  </div>
                  <div className="absolute inset-x-0 bottom-0">
                    <img src={IronSol3} alt="Iron Studio" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Solution bg */}
          <div>
            <div className="container mx-auto">
              <img src={ironSolbg} alt="Iron Studio" />
            </div>
          </div>

          {/* Typographics */}

          <div className="mb-60 md:mb-20 xl:mb-1-40 ">
            <div className="container mx-auto">
              <div className="mb-6 md:mb-10 iron-typo">
                <div className="w-full">
                  <h3 className="mb-8  xl:mb-16 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-white">
                    Typeface & Colors
                  </h3>
                </div>
              </div>
              <div className="flex items-center justify-between gap-6">
                <div className="w-full md:w-2/5 iron-typo-inner ">
                  <h3 className="mb-2 lg:mb-4 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-white">
                    Lato
                  </h3>
                  <h5 className="mb-1 text-white opacity-70">
                    ABCDEFGHIJKLMNOPQRSTUVWXYZ
                  </h5>
                  <h5 className="mb-1 text-white opacity-70">
                    abcdefghijklmnopqrstuvwxyz
                  </h5>
                  <h5 className="mb-1 text-white opacity-70">1234567890</h5>
                </div>
                <div className="w-full md:w-3/5 flex flex-end items-center justify-end gap-6">
                  <div className="iron-color-plate flex items-center justify-center gap-2 border border-white">
                    <h5 className="text-white font-poppings-bold text-center">
                      #000000
                    </h5>
                  </div>
                  <div className="iron-color-plate flex items-center justify-center gap-2 border border-white">
                    <h5 className="text-white font-poppings-bold text-center">
                      #666666
                    </h5>
                  </div>
                  <div className="iron-color-plate flex items-center justify-center gap-2 border border-white">
                    <h5 className="text-white font-poppings-bold text-center">
                      #4B3025
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Result section */}
          <section className="mb-60 md:mb-20">
            <div className="container mx-auto">
              <div className="w-full sm:w-2/3 lg:max-w-3xl">
                <h2 className="mb-4 lg:mb-8 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-white">
                  The Result
                </h2>
                <p className="mb-2 lg:mb-3 text-p4 lg:text-p3 xl:text-p2 text-white font-worksans-normal">
                  We delivered a visually appealing, streamlined, site that is
                  both pleasing to view and easy to navigate.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-6 text-p4 lg:text-p3 xl:text-p2 text-white font-worksans-normal">
                  The result? Bernado loved the design! When the client is
                  happy, we are happy too. A good website is never complete, you
                  can always update it like your home.
                </p>

                <div className="w-full">
                  <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-white">
                    How can our team make you happy today? Whether it’s a new
                    website from scratch or a reboot of your existing one,
                    DecodeUp's designers can make it happen.
                  </h3>
                </div>
              </div>
            </div>
          </section>
          {/* slider section */}
          <section className="pb-60 md:pb-20">
            <div className="case-study-slider-bg">
              <div className="container container-cor mx-auto">
                <div
                  id="myid"
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                >
                  <Flickity
                    className={"case-studies-carousel cstm-du-casestudy-slider"}
                    elementType={"div"}
                    options={options}
                    ref={flRef}
                  >
                    <img
                      className="cs-carousel-image rounded-2xl "
                      src={Ironstoress1}
                      alt="Iron Decodeup Technologies"
                    />
                    <img
                      className="cs-carousel-image rounded-2xl "
                      src={Ironstoress2}
                      alt="Iron Decodeup Technologies"
                    />
                    <img
                      className="cs-carousel-image rounded-2xl "
                      src={Ironstoress3}
                      alt="Iron Decodeup Technologies"
                    />
                    <img
                      className="cs-carousel-image rounded-2xl "
                      src={Ironstoress4}
                      alt="Iron Decodeup Technologies"
                    />
                    <img
                      className="cs-carousel-image rounded-2xl "
                      src={Ironstoress5}
                      alt="Iron Decodeup Technologies"
                    />
                    <img
                      className="cs-carousel-image rounded-2xl "
                      src={Ironstoress6}
                      alt="Iron Decodeup Technologies"
                    />
                    <img
                      className="cs-carousel-image rounded-2xl "
                      src={Ironstoress7}
                      alt="Iron Decodeup Technologies"
                    />
                    <img
                      className="cs-carousel-image rounded-2xl "
                      src={Ironstoress8}
                      alt="Iron Decodeup Technologies"
                    />
                  </Flickity>
                </div>
              </div>
            </div>
          </section>
        </section>

        {/* client testimonials carousel section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
              <TestimonialsText />
            </div>
          </div>
        </section>

        {/* additional case studies section */}

        <section className="pb-50 md:pt-50 lg:pt-62 xl:pt-0 lg:pb-1-20">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-36 text-center">
              <h3 className="d-head-title text-rp1  lg:text-h3 font-poppings-bold text-term-primary">
                Additional Case Studies
              </h3>
            </div>
            <div className="h-full flex flex-wrap">
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-cyan">
                <Link
                  to="/case-study/luxury-real-estatemedia-managements"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Luxury Real Estate Media Management System
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=""
                      src={addcs1}
                      alt="Square One Media Productions Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-background-case">
                <Link
                  // to={post.fields.slug}
                  to="/case-study/medications-tracker"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Medications Tracker
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=""
                      src={addcs2}
                      alt="Medication Tracker Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <LiveChat />
      </Layout>
    </>
  )
}

export default IronsTudio

export const pageQuery = graphql`
query ironstudiospagequery {
  pageData: allMarkdownRemark(
    filter: { fileAbsolutePath: { regex: "/case-studies/action-figures-online-studio.md/" } }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }     
}

`;